import {outboundLinks, pages, queryParamKeys} from '../constants-enumerate';

/**
 * This object is used in init-router-service and will match a source
 * url with the appropriate re-direct url.
 * This is often used with vanity to source appropriate affiliates.
 */

export const redirectUrls = {
  '/customer-support/': outboundLinks.CUSTOMER_SUPPORT,
  '/customer-support': outboundLinks.CUSTOMER_SUPPORT,
  '/curriculum/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:kd`,
  '/curriculum': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:kd`,
  '/forgot-password/': outboundLinks.CUSTOMER_SUPPORT_FORGOT_PASSWORD,
  '/forgot-password': outboundLinks.CUSTOMER_SUPPORT_FORGOT_PASSWORD,
  '/mops/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:MOPS&${queryParamKeys.UTM_ID}=2023-05-06&${queryParamKeys.UTM_SOURCE}=MOPS&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/mops': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:MOPS&${queryParamKeys.UTM_ID}=2023-05-06&${queryParamKeys.UTM_SOURCE}=MOPS&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/hslda': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:HSLDA&${queryParamKeys.UTM_ID}=2023-04-12&${queryParamKeys.UTM_SOURCE}=HSLDA&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/hslda/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:HSLDA&${queryParamKeys.UTM_ID}=2023-04-12&${queryParamKeys.UTM_SOURCE}=HSLDA&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/hsyt/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=ABCmYT:2023&${queryParamKeys.UTM_ID}=2023-09&${queryParamKeys.UTM_SOURCE}=ABCmYT&${queryParamKeys.UTM_CAMPAIGN}=ABCmYT&${queryParamKeys.UTM_MEDIUM}=Youtube`,
  '/hsyt': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=ABCmYT:2023&${queryParamKeys.UTM_ID}=2023-09&${queryParamKeys.UTM_SOURCE}=ABCmYT&${queryParamKeys.UTM_CAMPAIGN}=ABCmYT&${queryParamKeys.UTM_MEDIUM}=Youtube`,
  '/tos': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:TOS&${queryParamKeys.UTM_ID}=2023-06-01&${queryParamKeys.UTM_SOURCE}=TOS&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/tos/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:TOS&${queryParamKeys.UTM_ID}=2023-06-01&${queryParamKeys.UTM_SOURCE}=TOS&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/phs': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:PHS&${queryParamKeys.UTM_ID}=2023-05-01&${queryParamKeys.UTM_SOURCE}=PHS&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/phs/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff:hs:printads:PHS&${queryParamKeys.UTM_ID}=2023-05-01&${queryParamKeys.UTM_SOURCE}=PHS&${queryParamKeys.UTM_CAMPAIGN}=Spring+23&${queryParamKeys.UTM_MEDIUM}=Print`,
  '/fpea': pages.SUBSCRIPTION,
  '/fpea/': pages.SUBSCRIPTION,
  '/ghc': pages.SUBSCRIPTION,
  '/ghc/': pages.SUBSCRIPTION,
  '/lft': `${pages.SUBSCRIPTION}?${queryParamKeys.SOURCE_TAG}=aff:hs:BHG:LFT:20230823&${queryParamKeys.UTM_ID}=2023-08-23&${queryParamKeys.UTM_SOURCE}=lft&${queryParamKeys.UTM_CAMPAIGN}=b2s+23&${queryParamKeys.UTM_MEDIUM}=YouTube`,
  '/lft/': `${pages.SUBSCRIPTION}?${queryParamKeys.SOURCE_TAG}=aff:hs:BHG:LFT:20230823&${queryParamKeys.UTM_ID}=2023-08-23&${queryParamKeys.UTM_SOURCE}=lft&${queryParamKeys.UTM_CAMPAIGN}=b2s+23&${queryParamKeys.UTM_MEDIUM}=YouTube`,
  '/subscription/offer/0a645a/': pages.HOME,
  '/subscription/offer/0a645a': pages.HOME,
  '/privacy-policy/': outboundLinks.PRIVACY_POLICY,
  '/privacy-policy': outboundLinks.PRIVACY_POLICY,
  '/privacy-policy/current': outboundLinks.PRIVACY_POLICY,
  '/privacy-policy/current/': outboundLinks.PRIVACY_POLICY,
  '/privacy-policy/previous': outboundLinks.PRIVACY_POLICY,
  '/privacy-policy/previous/': outboundLinks.PRIVACY_POLICY,
  '/terms-and-conditions/': outboundLinks.TERMS_CONDITIONS,
  '/terms-and-conditions': outboundLinks.TERMS_CONDITIONS,
  '/terms-and-conditions/current': outboundLinks.TERMS_CONDITIONS,
  '/terms-and-conditions/current/': outboundLinks.TERMS_CONDITIONS,
  '/terms-and-conditions/previous': outboundLinks.TERMS_CONDITIONS,
  '/terms-and-conditions/previous/': outboundLinks.TERMS_CONDITIONS,
};
