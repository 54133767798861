import './init-router-service';
import './route-view';
import {environment} from './constants-enumerate';

/* istanbul ignore next */
if (location.protocol === 'https:' && environment.PROD && 'serviceWorker' in navigator &&
typeof aofljsConfig.__prerender__ === 'undefined') {
  navigator.serviceWorker.register(environment.PUBLIC_PATH + 'sw.js', {
    scope: environment.PUBLIC_PATH
  });
}
