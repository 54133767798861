import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {mapStatePropertiesMixin} from '@aofl/map-state-properties-mixin';
import {storeInstance} from '@aofl/store/dist/legacy';
import {userInfoSdo} from '../sdo-user-info';

/**
 * @summary AuthstateContext
 * @extends {mapStatePropertiesMixin(AoflElement)}
 */
@customElement('authstate-context')
class AuthstateContext extends mapStatePropertiesMixin(AoflElement) {
  /**
   * Creates an instance of AuthstateContext.
   */
  constructor() {
    super();
    this.storeInstance = storeInstance;
  }

  /**
   * @readonly
   */
  static is = 'authstate-context';

  /**
   * @return {Object}
   */
  @property({type: Boolean})
  'show-when-loggedin' = false;

  /**
   *
   */
  mapStateProperties() {
    if (userInfoSdo.isLoggedIn) {
      if (this['show-when-loggedin']) {
        this.classList.remove('hide');
      } else {
        this.classList.add('hide');
      }
    } else {
      if (this['show-when-loggedin']) {
        this.classList.add('hide');
      } else {
        this.classList.remove('hide');
      }
    }
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default AuthstateContext;
