import {SimpleModals} from './simple-modal';
import {layoutSdo} from './sdo-layout';

/**
 *
 * @class ModalService
 */
class ModalService {
  /**
   *
   * @static
   * @param {Object} modalsConfig
   */
  static addModals(modalsConfig) {
    SimpleModals.addModals(modalsConfig);
  }

  /**
   *
   * @static
   * @param {String} modalName
   */
  static show(modalName, scrollOptions = {}) {
    const payload = {
      top: scrollOptions?.top ?? window.pageYOffset,
      left: scrollOptions?.left ?? window.pageXOffset,
      freeze: true,
      scrollToTop: scrollOptions?.scrollToTop ?? false
    };
    layoutSdo.scroll = payload;
    layoutSdo.overlay = true;
    SimpleModals.show(modalName);
    document.activeElement.blur();
  }

  /**
   *
   * @static
   */
  static hide() {
    SimpleModals.hide();
    layoutSdo.overlay = false;
    layoutSdo.scrollFreeze(false);
  }
}

export {
  ModalService
};

