/**
 * @route /404/
 * @locale en
 * @title Page Not Found
 * @prerender false
 * @meta {
 *   inactiveRedirect: true,
 *   pageInfo: [{
 *     page_info_key: '404-page'
 *   }]
 * }
 * @linkTag {
 *   rel: 'canonical',
 *   href: 'https://www.homeschoolplus.com/404'
 * }
 */
import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {routerInstance} from '@aofl/router';
import {accessibilityMixin} from '../accessibility-mixin';
import {ApiService} from '../api-service';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {pages} from '../constants-enumerate';
import {UserInfoService} from '../user-info-service';
import {userInfoSdo} from '../sdo-user-info';

/**
 * @summary CdivPage
 * @extends {clickTrackerMixin(AoflElement)}
 */
@customElement('cdiv-page')
class CdivPage extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * @readonly
   */
  static is = 'cdiv-page';

  /**
   * @param {Event} e
   */
  goHomeClick(e) {
    const accountStatus = userInfoSdo.userInfo.accountStatus;
    if (accountStatus === 'inactive') {
      return routerInstance.navigate(pages.REACTIVATION);
    }
    return routerInstance.navigate(pages.HOME);
  }

  /**
   *
   * @param {*} e
   */
  async logOut(e) {
    e.preventDefault();
    try {
      await ApiService.userLogout();
      UserInfoService.clear();
      localStorage.clear();
      sessionStorage.clear();
      routerInstance.navigate(pages.HOME, {});
    } catch (e) {}
  }

  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default CdivPage;
