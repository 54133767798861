
/* eslint-disable */
const {cookies, queryParamKeys} = require('../constants-enumerate');
import Cookies from 'js-cookie';

const urlSearchParams = new URLSearchParams(window.location.search);
const wdio = urlSearchParams.get(queryParamKeys.WDIO);
const wdioSuppressPixels = urlSearchParams.get(queryParamKeys.WDIO_SUPPRESS_PIXELS);
const wdioTestEmail = urlSearchParams.get(queryParamKeys.WDIO_TEST_EMAIL);
const wdioSessionId = urlSearchParams.get(queryParamKeys.WDIO_SESSION_ID);
const wdioCountryCode = urlSearchParams.get(queryParamKeys.WDIO_COUNTRY_CODE);
const wdioRegionCode = urlSearchParams.get(queryParamKeys.WDIO_REGION_CODE);
const wdioBlockGdprBanner = urlSearchParams.get(queryParamKeys.WDIO_BLOCK_GDPR_BANNER);

if (wdio) {
  Cookies.set(cookies.WDIO, wdio, cookies.CONFIG);
}

if (wdioTestEmail) {
  Cookies.set(cookies.WDIO_TEST_EMAIL, wdioTestEmail, cookies.CONFIG);
}

if (wdioSessionId) {
  Cookies.set(cookies.WDIO_SESSION_ID, wdioSessionId, cookies.CONFIG);
}

if (wdioSuppressPixels === 'true') {
  Cookies.set(cookies.WDIO_SUPPRESS_PIXELS, 'true', cookies.CONFIG);
}

if (wdioCountryCode) {
  Cookies.set(cookies.WDIO_COUNTRY_CODE, wdioCountryCode, cookies.CONFIG);
}

if (wdioRegionCode) {
  Cookies.set(cookies.WDIO_REGION_CODE, wdioRegionCode, cookies.CONFIG);
}

if (wdioBlockGdprBanner) {
  Cookies.set(cookies.WDIO_BLOCK_GDPR_BANNER, 'true', cookies.CONFIG);
}
