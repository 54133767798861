/* eslint-disable */
import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const imageConfig = {
  closeButtonBlack: [
    {src: `${isNotQualified ? '/assets/templates/modal-close-button/modal-close-button-black-v2.svg' : '/assets/templates/modal-close-button/modal-close-button-black-v2.svg'}`}
  ],
  closeButtonWhite: [
    {src: `${isNotQualified ? '/assets/templates/modal-close-button/modal-close-button-white-v2.svg' : '/assets/templates/modal-close-button/modal-close-button-white-v2.svg'}`}
  ],
};

export {
  imageConfig
};
