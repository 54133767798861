/**
 * Please keep all constants in alphabetical order, including keys in the objects
 */
import {environmentTypeEnumerate, getServerEnvironment} from '@aofl/server-environment';
import {IS_DESKTOP, IS_MAC, IS_WINDOWS, IS_CHROMEBOOK} from '@aoflmkt/is-desktop';
import Cookies from 'js-cookie';

const DEV_ENV_REGEX = /localhost|qat|dev/i;
const STAGE_DEV_REGEX = /stage/i;
const serverEnvironment = getServerEnvironment(DEV_ENV_REGEX, STAGE_DEV_REGEX);
const DEV = serverEnvironment === environmentTypeEnumerate.DEV;
const STAGE = serverEnvironment === environmentTypeEnumerate.STAGE;
const PROD = !DEV && !STAGE;
const SECURE = location.protocol === 'https:';

const actionSources = {
  EMAIL_SPECIAL_OFFER_UPGRADE: 'email_special_offer_upgrade',
  REG_PATH: 'regpath'
};

const aoflProducts = {
  HOMESCHOOL_PRODUCT_UUID: '4be90567-6d1a-43ab-afe6-51faa8d847eb'
};

const apis = {
  FORMATTER: 'marketing-json',
  DEV_HOSTNAME: 'https://dev.homeschoolplus.com',
  PROD_HOSTNAME: 'https://www.homeschoolplus.com',
  STG_HOSTNAME: 'https://stg.homeschoolplus.com',
  API_PATH: '/ws/amsl/0.1/json',
  MARKETING: 'homeschoolMarketing'
};

const apiFormatters = {
  HOMESCHOOL_JSON: 'homeschool-json'
};

const appCalls = {
  EVENT: 'event',
  LOGIN: 'login',
  OPEN_URI: 'open_uri',
  PURCHASE: 'purchase',
  RESTORE_PURCHASE: 'restorePurchase',
};


const appCallbackKey = {
  EVENT: 'eventCallback',
  PURCHASE: 'purchaseCallback',
  RESTORE_PURCHASE: 'restorePurchaseCallback'
};

const appStores = {
  ITUNES: 'itunes'
};

const cacheNamespaces = {
  API_AUTH: 'marketing-auth',
  CAMPAIGN_PIXELS_LOG: 'campaign-pixels-log',
  PAYPAL_CHECKOUT_INSTANCE_DATA: 'paypal-checkout-instance-data',
  REACTIVATION_USER_DATA: 'reactivation-user-data',
  USER_SESSION: 'user-session',
  ROTATION_VERSION: 'rotation-version',
  SUBSCRIPTION_INFO: 'subscription-info',
  TIME_LAST_VISITED: 'time-last-visited',
  ITUNES_RECEIPT: 'itunes-receipt',
};

const conversionEvents = {
  GREAT_HOMESCHOOL_CONVENTION: 'conv:ghsc'
};

const cookies = {
  CAMPAIGN_INFO: 'campaign_info',
  CJ_D_COOKIE: 'cjd',
  CJ_E_COOKIE: 'cje',
  CJ_CONFIG: {
    secure: SECURE,
    expires: 35
  },
  CONFIG: {
    secure: SECURE,
    expires: 365,
  },
  ELOG: 'elog_email',
  GDPR: 'gdpr',
  LINK_FROM_APP: 'link_from_app',
  RESET_PASSWORD: '272f199969',
  COUNTRY_CODE: 'cloudfront-viewer-country',
  REGION: 'cloudfront-viewer-country-region',
  APP_STORE: 'app_store',
  WDIO: 'wdio',
  WDIO_BLOCK_GDPR_BANNER: 'wdio_block_gdpr_banner',
  WDIO_COUNTRY_CODE: 'wdio_country_code',
  WDIO_REGION_CODE: 'wdio_region_code',
  WDIO_SUPPRESS_PIXELS: 'wdio_suppress_pixels',
  WDIO_TEST_EMAIL: 'wdio_test_email',
  WDIO_SESSION_ID: 'wdio_session_id'
};

const cookiesConsent = {
  ANALYTICS: 'ANALYTICS_PERFORMANCE_COOKIES',
  TARGETING: 'TARGETING_COOKIES'
};

const countryCodesGDPR = {
  AT: 'Austria',
  AU: 'Australia',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Republic of Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HU: 'Hungary',
  HR: 'Croatia',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SK: 'Slovakia',
  SI: 'Slovenia'
};

const headers = {
  IS_IOS: 'cloudfront-is-ios-viewer',
  IS_DESKTOP: 'cloudfront-is-desktop-viewer',
  IS_ANDROID: 'cloudfront-is-android-viewer',
  IS_TABLET: 'cloudfront-is-tablet-viewer',
  IS_MOBILE: 'cloudfront-is-mobile-viewer',
  COUNTRY_REGION_NAME: 'cloudfront-viewer-country-region-name',
  CITY: 'cloudfront-viewer-city',
  COUNTRY_NAME: 'cloudfront-viewer-country-name',
  COUNTRY_REGION: 'cloudfront-viewer-country-region',
  COUNTRY: 'cloudfront-viewer-country',
};

const localStorageKeys = {
  AOFL_TOKEN: 'flutter.aofl_token',
  APP_TOKEN: 'flutter.auth_token',
  CONVERSION_EVENT_ID: 'conversion_event_id',
  CURRENT_PRODUCT_HASH: 'currentProductHash',
  DEVICE_ID: 'flutter.device_id',
  EMAIL: 'email',
  REDEEM_CODE: 'redeemCode',
  WDIO_BLOCK_GDPR_BANNER: 'wdio_block_gdpr_banner',
};

const logEventTokens = {
  FIRST_LOGIN: 'git37d',
  LOGIN_LOAD: 'xil2iz',
  LOGIN: 'p4dvbw',
  SUBSCRIPTION: 'tj6s5f',
};

const logEventTypes = {
  ADJUST: 'adjust',
};

const queryParamKeys = {
  APP_STORE: 'store',
  CJ_DATA: 'cjdata',
  CJ_EVENT: 'cjevent',
  CODE: 'code',
  EMAIL_CLICK: '_0c83',
  LINK_FROM_APP: 'linkFromApp',
  SOURCE_TAG: 'src_tag',
  TO: 'to',
  TOKEN: 'homeschoolMarketingToken',
  WDIO: 'wdio_rotation_version',
  WDIO_BLOCK_GDPR_BANNER: 'wdio_block_gdpr_banner',
  WDIO_COUNTRY_CODE: 'wdio_country_code',
  WDIO_REGION_CODE: 'wdio_region_code',
  WDIO_SUPPRESS_PIXELS: 'wdio_suppress_pixels',
  WDIO_TEST_EMAIL: 'wdio_test_email',
  WDIO_SESSION_ID: 'wdio_session_id',
  ROTATION_VERSION: 'rotationVersion',
  UTM_ID: 'utm_id',
  UTM_SOURCE: 'utm_source',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_MEDIUM: 'utm_medium',
  DNSMPI: 'dnsmpi',
};

const queryParamValues = {
  DNSMPI: '1'
};

const urlSearchParams = new URLSearchParams(window.location.search);
const isIosApp = Cookies.get(cookies.APP_STORE)?.toLowerCase() === appStores.ITUNES || urlSearchParams.get(queryParamKeys.APP_STORE) === appStores.ITUNES; // eslint-disable-line

const environment = {
  PUBLIC_PATH: __webpack_public_path__,
  IN_APP: Boolean(window.FlutterCallback),
  IS_CHROMEBOOK,
  IS_DESKTOP,
  IS_MAC,
  IS_IOS: isIosApp,
  IS_WINDOWS,
  PLATFORM: 'marketing',
  SECURE,
  STAGE,
  DEV,
  PROD
};

const eventNames = {
  CAMPAIGN_CLICK: 'marketing.campaign_click',
  LINK_CLICK: 'marketing.page_link_click',
  PAGE_LOAD: 'marketing.page_load',
  PIXEL_FIRE: 'marketing.pixel_fire',
  ROTATION_ASSIGN: 'marketing.rotation_assignment',
  VIDEO_PROGRESS: 'marketing.video_watched'
};

const outboundLinks = {
  ABOUT_AOFL: 'https://homeschoolplus.com/learn/about-age-of-learning',
  ALLABOUT_DNT: 'http://www.allaboutdnt.org',
  AMAZON_APP_STORE: 'amzn://apps/android?asin',
  CCPA_METRICS: 'https://www.ageoflearning.com/ccpa/',
  CURRICULUMS: 'https://homeschoolplus.com/learn/curriculum/arts-sciences-general-knowledge',
  CURRICULUM_ANIMALS_HABITATS_I: 'https://homeschoolplus.com/learn/curriculum/animal-habitats-I',
  CURRICULUM_ANIMALS_HABITATS_II: 'https://homeschoolplus.com/learn/curriculum/animal-habitats-II',
  CURRICULUM_ART: 'https://homeschoolplus.com/learn/curriculum/subject/art',
  CURRICULUM_ART_COLORS_SHAPES: 'https://homeschoolplus.com/learn/curriculum/art-colors-shapes',
  CURRICULUM_COMMUNITY_CITIZENSHIP_I: 'https://homeschoolplus.com/learn/curriculum/community-citizenship-I',
  CURRICULUM_COMMUNITY_CITIZENSHIP_II: 'https://homeschoolplus.com/learn/curriculum/community-citizenship-II',
  CURRICULUM_COURSE_READING_COMPREHENSION: 'https://homeschoolplus.com/learn/curriculum/course-reading-comprehension',
  CURRICULUM_CULTURES_PLACES_TO_EXPLORE: 'https://homeschoolplus.com/learn/curriculum/cultures-and-places-to-explore',
  CURRICULUM_FAQ: 'https://homeschoolplus.com/learn/faq',
  CURRICULUM_FIRST_GRADE: 'https://homeschoolplus.com/learn/curriculum/first-grade',
  CURRICULUM_FIRST_SPANISH_WORDS: 'https://homeschoolplus.com/learn/curriculum/first-spanish-words',
  CURRICULUM_GRAMMAR_WRITING: 'https://homeschoolplus.com/learn/curriculum/grammar-writing',
  CURRICULUM_KINDERGARTEN: 'https://homeschoolplus.com/learn/curriculum/kindergarten',
  CURRICULUM_LANGUAGE_ARTS: 'https://homeschoolplus.com/learn/curriculum/subject/language-arts',
  CURRICULUM_MAPS_LANDMARKS: 'https://homeschoolplus.com/learn/curriculum/maps-and-landmarks',
  CURRICULUM_MATH: 'https://homeschoolplus.com/learn/curriculum/subject/math',
  CURRICULUM_MY_MATH_ACADEMY: 'https://homeschoolplus.com/learn/curriculum/my-math-academy',
  CURRICULUM_MY_READING_ACADEMY: 'https://homeschoolplus.com/learn/curriculum/my-reading-academy',
  CURRICULUM_PIANO_SUBJECT: 'https://www.homeschoolplus.com/learn/curriculum/subject/piano-homeschool-curriculum',
  CURRICULUM_PIANO: 'https://homeschoolplus.com/learn/curriculum/piano-1',
  CURRICULUM_PIANO_II: 'https://www.homeschoolplus.com/learn/curriculum/course-piano-2',
  CURRICULUM_PLANTS: 'https://homeschoolplus.com/learn/curriculum/plants',
  CURRICULUM_PRESCHOOL: 'https://homeschoolplus.com/learn/curriculum/preschool',
  CURRICULUM_READING: 'https://homeschoolplus.com/learn/curriculum/subject/reading',
  CURRICULUM_SCIENCE: 'https://homeschoolplus.com/learn/curriculum/subject/science',
  CURRICULUM_SCIENCE_EXPERIMENTS: 'https://homeschoolplus.com/learn/curriculum/science-experiments',
  CURRICULUM_SECOND_GRADE: 'https://homeschoolplus.com/learn/curriculum/second-grade',
  CURRICULUM_SOCIAL_STUDIES: 'https://homeschoolplus.com/learn/curriculum/subject/social-studies',
  CURRICULUM_SPANISH: 'https://homeschoolplus.com/learn/curriculum/subject/spanish',
  CURRICULUM_SPELLING: 'https://www.homeschoolplus.com/learn/curriculum/course-spelling',
  CURRICULUM_SYSTEMS_OF_HUMAN_BODY: 'https://homeschoolplus.com/learn/curriculum/systems-of-the-human-body',
  CURRICULUM_THINK_LIKE_SCIENTIST: 'https://homeschoolplus.com/learn/curriculum/think-like-a-scientist',
  CURRICULUM_WEATHER: 'https://homeschoolplus.com/learn/curriculum/weather',
  CURRICULUM_WRITING_FOR_DIFFERENT_PURPOSES: 'https://homeschoolplus.com/learn/curriculum/writing-for-different-purposes',
  CUSTOMER_SUPPORT: environment.PROD ? 'https://support.homeschoolplus.com/hc/en-us' : 'https://aofl1522884075.zendesk.com/hc/en-us',
  CUSTOMER_SUPPORT_CONTACT_US: 'https://support.abcmouse.com/hc/en-us/articles/360047572854',
  CUSTOMER_SUPPORT_FORGOT_PASSWORD: 'https://support.homeschoolplus.com/hc/en-us/articles/10508570741271?forgotpassword=true',
  GOOGLE_APP_STORE: 'https://play.google.com/store/apps/details?id=com.aofl.homeschoolplus',
  HOME_EDUCATOR_TOOLS: 'https://homeschoolplus.com/learn/home-educator-tools',
  HOME_EDUCATOR_TOOLS_LESSON_PLANNER: 'https://homeschoolplus.com/learn/home-educator-tools/lesson-planner',
  HOME_EDUCATOR_TOOLS_LESSON_PLANS: 'https://homeschoolplus.com/learn/home-educator-tools/lesson-plans',
  HOME_EDUCATOR_TOOLS_PROGRESS_TRACKER: 'https://homeschoolplus.com/learn/home-educator-tools/progress-tracker',
  HOMESCHOOL_LAWS: 'https://www.homeschoolplus.com/learn/homeschool-laws',
  HOMESCHOOL_PLUS_CHARTERS: 'https://homeschoolplus.com/learn/resources/homeschool-charter-school',
  HOW_TO_START_HOMESCHOOLING: 'https://homeschoolplus.com/learn/resources/how-to-start-homeschooling',
  IOS_APP_STORE: 'https://apps.apple.com/us/app/homeschool/id6445926347',
  IPV6: environment.PROD ? 'https://ipv6.abcmouse.com/trk' : 'https://deq4mt61w2hci.cloudfront.net/trk',
  PRIVACY_PORTAL: 'https://privacyportal-cdn.onetrust.com/dsarwebform/a2692794-047c-4c18-b3bf-60d783ebc844/8205df50-001c-4e16-b5f9-d4e945bc612e.html',
  PRODUCT_GUIDE: 'https://homeschoolplus.com/learn/product-guide',
  AOFL_HUB: 'https://www.ageoflearning.com/privacy-policies',
  PRIVACY_POLICY: 'https://www.ageoflearning.com/hs-privacy-policy-current',
  TERMS_CONDITIONS: 'https://www.ageoflearning.com/hs-tanc-current',
};

const regStepsEnumerate = {
  COMPLETE: 'REGPATH_LAST_STEP',
  DOWNLOAD_APP: 'REGPATH_DOWNLOAD_APP',
  UPGRADE: 'REGPATH_UPGRADE',
  SURVEY: 'REGPATH_SURVEY',
  LAST_STEP: 'REGPATH_LAST_STEP'
};

const pages = {
  [regStepsEnumerate.UPGRADE]: environment.PUBLIC_PATH + 'upgrade/',
  [regStepsEnumerate.SURVEY]: environment.PUBLIC_PATH + 'survey/',
  [regStepsEnumerate.LAST_STEP]: environment.PUBLIC_PATH + 'download-app/',
  ACCESSIBILITY_STATEMENT: environment.PUBLIC_PATH + 'accessibility-statement/',
  ACCESSIBILITY_STATEMENT_LINK_FROM_APP: environment.PUBLIC_PATH + 'accessibility-statement/?linkFromApp=true',
  CDIV: environment.PUBLIC_PATH + '404/',
  COOKIE_POLICY: environment.PUBLIC_PATH + 'cookie-policy/',
  CLEAR: environment.PUBLIC_PATH + 'clear/',
  CONFIRMATION: environment.PUBLIC_PATH + 'confirmation/',
  CUSTOMER_SUPPORT: environment.PUBLIC_PATH + 'customer-support/',
  DOWNLOAD_APP: environment.PUBLIC_PATH + 'download-app/',
  ERROR: environment.PUBLIC_PATH + 'error-page/',
  FAQ: environment.PUBLIC_PATH + 'faq/',
  FORGOT_PASSWORD: environment.PUBLIC_PATH + 'forgot-password/',
  HOME: environment.PUBLIC_PATH,
  IOS_CREATE_ACCOUNT: environment.PUBLIC_PATH + 'ios-create-account/',
  LOGIN: environment.PUBLIC_PATH + 'login/',
  PRIVACY_POLICY: outboundLinks.PRIVACY_POLICY,
  PRIVACY_POLICY_LINK_FROM_APP: outboundLinks.PRIVACY_POLICY + '?linkFromApp=true',
  PRIVACY_POLICY_PREVIOUS: outboundLinks.PRIVACY_POLICY,
  PRIVACY_POLICY_PREVIOUS_LINK_FROM_APP: outboundLinks.PRIVACY_POLICY + '?linkFromApp=true',
  REACTIVATION: environment.PUBLIC_PATH + 'reactivation/',
  REDEEM: environment.PUBLIC_PATH + 'redeem/',
  REDEEM_CREATE_ACCOUNT: environment.PUBLIC_PATH + 'redeem/create-account/',
  RESET_PASSWORD: environment.PUBLIC_PATH + 'reset-password/',
  SUBSCRIPTION: environment.PUBLIC_PATH + 'subscription/',
  SUBSCRIPTION_OFFER_SPECIAL_OFFER_15_FOR_12: environment.PUBLIC_PATH + 'subscription/offer/0a645a/',
  SUBSCRIPTION_OFFER_SPECIAL_OFFER_15_FOR_12_BETA_USERS: environment.PUBLIC_PATH + 'subscription/offer/7r621b/',
  TERMS_CONDITIONS: outboundLinks.TERMS_CONDITIONS,
  TERMS_CONDITIONS_LINK_FROM_APP: outboundLinks.TERMS_CONDITIONS + '?linkFromApp=true',
  TERMS_CONDITIONS_PREVIOUS: outboundLinks.AOFL_HUB,
  TERMS_CONDITIONS_PREVIOUS_LINK_FROM_APP: outboundLinks.AOFL_HUB + '?linkFromApp=true',
  UPGRADE: environment.PUBLIC_PATH + 'upgrade/',
  UPGRADE_STANDALONE: environment.PUBLIC_PATH + 'upgrade/sta/',
  WDIO_COMPLETE: environment.PUBLIC_PATH + 'wdio-complete/'
};

const paymentTypes = {
  CREDIT_CARD: 'paymentech',
  PAYPAL: 'paypal',
  ITUNES: 'itunes',
};

const pixelEvents = {
  CONVERSION: 'conversion',
  GLOBAL: 'global',
  LANDING: 'landing',
  LANDING_HOME: 'landingHome',
  LANDING_SUBSCRIPTION: 'landingSubscription',
  LOGIN: 'login',
  PROSPECT_REG: 'prospect-reg'
};

const platforms = {
  BROWSER: 'browser',
  DEFAULT: 'default_platform',
  IOS_APP: 'ios'
};

const products = {
  ANNUAL_NO_TRIAL_PAYPAL_47988: 'a2a60c36c772a6f3cb206249f4ac752c9502d98be5804b1227634a46bafcd66f',
  ANNUAL_TRIAL_PAYPAL_47988: '5ed83a0f152cc729111905c2193a55b4866d19d0140c6e04f84a82e435093ae8',
  ANNUAL_NO_TRIAL_47988: 'fea931e2f726bd9bc872fdc2eb7d04e9eab9b6234c556fe6cd66d513445632ed',
  ANNUAL_TRIAL_47988: '547a4b6ffe4579f24930150c9a5f7e53611707b009745957ab8170f269825f37',
  HOMESCHOOL_BETA_5FOR2: 'de7aed4bb764390a262ae6aa146561f8e46b65b6871aefcfa5b2b44cdb8eb003',
  MONTHLY_NO_TRIAL_4999: 'cf6d2a283a70a8e673e76eb10b39f319c41f73838a864e8d127fa835e04a41ea',
  MONTHLY_TRIAL_4999: 'e7d2e76b4f6aa8b4ca2d679466c32d95375c9e5dc2b52bdcdcd32c39b3902857',
  SPECIAL_OFFER_15_FOR_12: 'ead4cd40f38aa25fb9c2b0f582cc1fcfae746f831bee59ee8b5394cab501759b',
  SPECIAL_OFFER_15_FOR_12_PAYPAL: '162ad8a2ad1f9d989b331310d210ad9801a1f3b575e774fa22069b40060a1ffe',
  ANNUAL_TRIAL_375: '430c446daec29aaadecf12b097bb2b0ac0c9e321feb474809d2401ef10de5e21',
  ANNUAL_NO_TRIAL_375: '96cd89a96cbb82ae1bff0e89f53b9a24ddd7d170474fca7521b01476195ef68d',
  ANNUAL_TRIAL_PAYPAL_375: '911950a5a28b85aeae993763446037b5e40262b98bf69cc8696eb7cc5af2bd43',
  MONTHLY_TRIAL_3999: '79b59b2d085eda01fe06ad34710da8b80672cf96888dd22a5d32e4ed64ad0d7b',
  MONTHLY_TRIAL_2999: '1adf1c23b499d098579f4608fd67f94200e247ad411fc422eded9bbd1ca70ff7',
  MONTHLY_NO_TRIAL_3999: 'e4ef6259d3246406e4b44c8e056542ba86034200e58b6a448b268ec4a0ab0f87',
  ANNUAL_NO_TRIAL_PAYPAL_375: '93a26b5b60677e539a28edd77baac4da4a9547fadebab08c223bc599c3a09694',
  ANNUAL_TRIAL_299_99: 'f56ccc4c33e824e9eb59936ff10d5fa16c3c19f06b83e7630fa531d78c1b61dd',
  ANNUAL_NO_TRIAL_299_99: '997419856d98649726469ec96b7232ddf30447a7787da75983ede4a6a7b49f4f',
  ANNUAL_TRIAL_PAYPAL_299_99: '22997a3c3c8069523a78abd9d5b35baf178621fee1f9b5a6d6c9399953615a4d',
  ANNUAL_TRIAL_299_99_15_FOR_12: 'c3fb63c27258c69b8fda4b15857d975b5bee7977c0c6b6db61105d88ea2dc0c3',
  ANNUAL_299_99_15_FOR_12: 'ca2c9cfef48784848cb0a52b456e75ef8913c1ea94b446350d10fa14be9d0ddc',
  ANNUAL_PAYPAL_299_99_15_FOR_12: 'af4bca335dc6389d5c06deeed736ddb84b018c1c1fbbfb4040d32d5691d1275d',
  ANNUAL_TRIAL_199_99: '9717432b930e203830435e1a07729c7ae1dd265f7e027b6aaea20374b7c242a4',
  ANNUAL_NO_TRIAL_199_99: 'a23208916de882c125c8e266d52ec662fe42b58e317122cb06c2fd8afb015d48',
  ANNUAL_TRIAL_299_99_NON_RECURRING: '428dfeaf3843fd324d708d81c409dff465e15fe66e08225affb8de1b40c94813',
  MONTHLY_IOS_TRIAL_49_99: '56a0220c6a934c98715d382623457f9f9cd8917208373ae02039b85004d6ff2f',
  ANNUAL_IOS_TRIAL_379_99: 'e1a5793b29286d9d36ae8dddee9886ff22e1c3e96fc420f5f329b559acd358f2',
  MONTHLY_IOS_NO_TRIAL_49_99: '5c12e9d4d643bed229bc0f9e728df29e78c330e9527e7bfc36a28286372da4d9',
  ANNUAL_IOS_TRIAL_NO_TRIAL_379_99: '6b6c9ee7eeaa069ee78b17d4f5e52e45b122512fc5fea916ccf03ca25cc6afd4',
  ANNUAL_UPGRADE_STANDALONE_199_99: 'a23208916de882c125c8e266d52ec662fe42b58e317122cb06c2fd8afb015d48'
};

const registrationTypes = {
  COMING_SOON: 'coming_soon'
};

const regPathKeys = {
  DEFAULT_SUBSCRIBER: 'defaultSubscriber',
  DEFAULT_RESUBSCRIBER: 'defaultResubscriber'
};

const rotations = {
  MARKETING: 'homeschoolMarketing'
};

const subscriptionStatus = {
  OPTED_IN: 'opted_in',
  UNSUBSCRIBED: 'unsubscribed'
};

const userNameTypes = {
  DEVICE_ID: 'device_id',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobile_number'
};

export {
  actionSources,
  aoflProducts,
  apis,
  apiFormatters,
  appCalls,
  appCallbackKey,
  appStores,
  cacheNamespaces,
  conversionEvents,
  cookies,
  cookiesConsent,
  countryCodesGDPR,
  environment,
  eventNames,
  headers,
  localStorageKeys,
  logEventTokens,
  logEventTypes,
  outboundLinks,
  pages,
  paymentTypes,
  pixelEvents,
  platforms,
  products,
  queryParamKeys,
  queryParamValues,
  registrationTypes,
  regPathKeys,
  regStepsEnumerate,
  rotations,
  serverEnvironment,
  subscriptionStatus,
  userNameTypes
};
