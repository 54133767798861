import {ApiRequest} from '@aofl/api-request';
import {aoflProducts, apiFormatters, apis, environment, localStorageKeys} from './constants-enumerate';
import MarketingJsonFormatter from './__config/marketing-json-formatter';

const apiRequest = new ApiRequest();
apiRequest.addFormatter(apiFormatters.HOMESCHOOL_JSON, MarketingJsonFormatter);

/**
 *
 * Please keep all api endpoints in alphabetical order, with the exception of request()
 */
class ApiService {
  /**
   *
   * @param {*} apiEndpoint
   * @param {*} payload
   * @param {*} format
   * @param {*} fromCache
   * @param {*} namespace
   */
  static async request(apiEndpoint, payload, format = apiFormatters.HOMESCHOOL_JSON, fromCache = true, namespace = 'default') {
    const apiHost = environment.DEV ? apis.DEV_HOSTNAME : location.origin;
    const url = `${apiHost}${apis.API_PATH}${apiEndpoint}`;
    const response = await apiRequest.request(url, payload, format, fromCache, namespace);
    return response;
  }

  /**
   *
   * Retrieves the pixel for the given campaign and event
   * @static
   * @param {String} event
   * @return {Promise}
   */
  static resolvePixelInfo(payload) {
    const request = {
      args: {
        product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        event: payload.event,
        campaign_info: payload.campaignInfo
      }
    };

    return this.request('/Campaign/ResolvePixelInfo/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static eventLog(payload) {
    const request = {
      args: {
        events: payload
      }
    };
    return this.request('/Event/Log/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static validateAccessCode(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        code: payload
      }
    };
    return this.request('/Item/Validate/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static paymentPreProcess(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        payment_provider: payload
      }
    };
    return this.request('/Payment/PreProcess/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static prospectRegisterEmail(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        email_address: payload.email,
        prospect_type: payload.prospectType,
        language_locale: payload.languageLocale,
        campaign_source_tag: payload?.campaignSourceTag,
        registration_type: payload?.registrationType,
        subscription_status: payload?.subscriptionStatus
      }
    };
    return this.request('/Prospect/RegisterEmail/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   * Logs clicks from email links.
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static logEmailClick(payload) {
    const request = {
      args: {
        request_url: payload.requestUrl,
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
      }
    };

    return this.request('/Recipient/LogEmailClick/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   * Unsubscribes user from the email list.
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static emailUnsubscribe(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        email_address: payload.email,
        subscription_status: payload.subscriptionStatus
      }
    };

    return this.request('/Recipient/UpdateStatus/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static getCompletedStep(payload) {
    const request = {
      args: {
        completed_step: payload
      }
    };
    return this.request('/RegistrationPath/GetCompletedStep/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   * Sends user an order confirmation email.
   */
  static orderConfirmation() {
    const request = {
      args: []
    };
    return this.request('/RegistrationPath/Complete/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static subscriberGetSubscriptionInfo() {
    const request = {
      args: []
    };
    return this.request('/Subscriber/GetSubscriptionInfo/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   * Creates a new subscription for existing user in current active session.
   * @param {Object} payload
   * @return {Promise}
   */
  static subscriptionCreate(payload) {
    const request = {
      args: {
        subscription_plan_hash: payload?.productHash,
        payment_provider: payload?.paymentType,
        payment_provider_info: payload?.paymentProviderInfo,
        phone_number: payload?.phone_number,
        ...(payload?.regPathKey && {registration_path_key: payload?.regPathKey}),
        ...(payload?.redeemCode && {code: payload?.redeemCode})
      }
    };
    return this.request('/Subscription/Create/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static subscriptionPreProcessCreate(payload) {
    const request = {
      args: {
        subscription_plan_hash: payload.productHash,
        payment_provider: payload.paymentType,
        payment_provider_info: payload.paymentProviderInfo
      }
    };
    return this.request('/Subscription/PreProcessCreate/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static subscriptionPreProcessSignUp(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        subscription_plan_hash: payload.productHash,
        credential: payload.credential,
        payment_provider: payload.paymentType,
        payment_provider_info: payload.paymentProviderInfo
      }
    };
    return this.request('/Subscription/PreProcessSignup/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   * Subscribe and create a new account
   * @param {Object} payload
   * @return {Promise}
   */
  static subscriptionSignUp(payload) {
    localStorage.setItem(localStorageKeys.CURRENT_PRODUCT_HASH, payload.productHash);

    const request = {
      args: {
        subscription_plan_hash: payload.productHash,
        credential: payload.credential,
        payment_provider: payload.paymentType,
        payment_provider_info: payload.paymentProviderInfo,
        phone_number: payload.phone_number,
        is_test: (environment.DEV || environment.STAGE),
        registration_path_key: payload.regPathKey,
        code: payload.code
      }
    };
    return this.request('/Subscription/SignUp/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object}
   * @return {Promise}
   */
  static terminate() {
    const request = {
      args: {}
    };
    return this.request('/Subscription/Terminate/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static subscriptionUpgrade(payload) {
    const request = {
      args: {
        subscription_plan_hash: payload.productHash,
        action_source: payload.actionSource
      }
    };
    return this.request('/Subscription/Upgrade/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @return {Promise}
   */
  static getUpgradeEligibility(payload) {
    const request = {
      args: {}
    };
    return this.request('/Subscription/UpgradeEligibility/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static submitSurvey(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        answers: payload.answers,
        user_to_product_uuid: payload.userUuid
      }
    };
    return this.request('/Survey/Answer/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   * Unsubscribes user from the email list.
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static getSurveyData(payload) {
    const request = {
      args: {
        survey_uuid: payload
      }
    };

    return this.request('/Survey/Get/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @return {Promise}
   */
  static userForgotPassword(payload) {
    const request = {
      args: {
        aofl_product_uuid: payload.aoflProduct,
        username_type: payload.usernameType,
        username: payload.username,
        country_abbrv: payload.countryCode
      }
    };
    return this.request('/User/ForgotPassword/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static login(payload) {
    const request = {
      args: {
        credential: {
          username: payload.username,
          password: payload.password,
          country_abbrv: payload.countryCode,
          username_type: payload.usernameType,
        },
        product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID
      }
    };
    return this.request('/User/Login/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static userLogout() {
    const request = {
      args: []
    };
    return this.request('/User/Logout/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @return {Promise}
   */
  static userResetPassword(payload) {
    const request = {
      args: {
        token: payload.token,
        aofl_product_uuid: payload.aoflProduct,
        credential: {
          password: payload.password,
          country_abbrv: payload.countryCode,
          username_type: payload.usernameType,
        },
      }
    };
    return this.request('/User/ResetPassword/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * @return {Promise}
   */
  static validateLogin() {
    const request = {
      args: []
    };
    return this.request('/User/ValidateLogin/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }

  /**
   *
   * Retrieves subscription information from a third party provider.
   * @static
   * @param {String} event
   * @return {Promise}
   */
  static thirdPartyProviderGetSubscription(payload) {
    const request = {
      args: {
        aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
        payment_provider: payload?.paymentType,
        payment_provider_info: payload?.paymentProviderInfo,
      }
    };

    return this.request('/ThirdPartyProvider/GetSubscription/init', request, apiFormatters.HOMESCHOOL_JSON, false);
  }
}

export {
  apiRequest,
  ApiService
};
