import {TrackerQueue} from '@aoflmkt/tracker-queue';
import {ApiService} from './api-service';

/**
 *
 * @extends {TrackerQueue}
 */
class MarketingTrackerQueue extends TrackerQueue {
  /**
   * Creates an instance of MarketingTrackerQueue.
   *
   */
  constructor() {
    super();
    this.interval = null;
    this.resetInterval();
    /* istanbul ignore next */
    window.addEventListener('beforeunload', () => {
      this.processQueue();
    });
  }
  /**
   * Overrides the base class method and decorates the event object with additional data.
   *
   * @param {Object} event
   */
  enqueueEvent(event) {
    super.enqueueEvent(event);
  }
  /**
   * Clears the current interval if one exists and starts a new interval and calls processQueue every 10s.
   *
   */
  resetInterval() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.processQueue();
    }, 1000);
  }
  /**
   * Calls send queue and passes a callback function that tries to make a call to event_log.
   *
   * @return {Promise}
   */
  processQueue() {
    return this.sendQueue((queue) => {
      return ApiService.eventLog(queue);
    });
  }
}

const marketingTrackerQueue = new MarketingTrackerQueue();

export {
  marketingTrackerQueue
};
