class CaptchaService {
  /**
   *
   * @param {Object} apiResponse
   * @static
   */
  static parseCaptchaResponse(apiResponse) {
    this.setResponseListener();
    this.setWindowProperties(apiResponse);
    this.addCaptchaScript(apiResponse.blockScript);
  }

  /**
   *
   * @param {String} blockScriptSrc
   * @static
   */
  static addCaptchaScript(blockScriptSrc) {
    const script = document.createElement('script');
    script.src = blockScriptSrc;
    document.getElementsByTagName('head')[0].appendChild(script);
    script.addEventListener('load', () => {
      this.showCaptchaModal();
    });
  }

  /**
   *
   * @param {Object} apiResponse
   * @static
   */
  static setWindowProperties(apiResponse) {
    window._pxAppId = apiResponse?.appId;
    window._pxJsClientSrc = apiResponse?.jsClientSrc;
    window._pxFirstPartyEnabled = apiResponse?.firstPartyEnabled;
    window._pxVid = apiResponse?.vid;
    window._pxUuid = apiResponse?.uuid;
    window._pxHostUrl = apiResponse?.hostUrl;
    window._pxreCaptchaTheme = 'light';
  }

  /**
   *
   * @static
   */
  static setResponseListener() {
    window._pxOnCaptchaSuccess = (isValid) => {
      this.hideCaptchaModal();
    };
  }


  /**
   *
   * @static
   */
  static async showCaptchaModal() {
    const {ModalService} = await import('./modal-service');

    ModalService.show('captchaModal', {
      scrollToTop: true
    });

    const email = localStorage.getItem('email');

    try {
      const {ApiService} = await import('./api-service');
      ApiService.eventLog({
        events: [
          {
            event_type: '@showReCaptcha',
            event_payload: {
              aofl_product: 'home_school',
              email: email || ''
            }
          }
        ]
      }); // eslint-disable-line
    } catch (e) {}
  }

  /**
   *
   * @static
   */
  static async hideCaptchaModal() {
    const {ModalService} = await import('./modal-service');
    ModalService.hide();
    try {
      const {ApiService} = await import('./api-service');
      ApiService.eventLog({
        events: [
          {
            event_type: '@hideReCaptcha'
          }
        ]
      }
      ); // eslint-disable-line
      localStorage.removeItem('email');
    } catch (e) {}
  }
}

export {
  CaptchaService
};
