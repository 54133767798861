const modalsConfig = {
  cookieConsentModal: {
    resolve: () => import('../cookie-consent-modal')
  },
  loaderIcon: {
    resolve: () => import('../loader-icon')
  }
};

modalsConfig.loaderIcon.resolve();

export {
  modalsConfig
};
