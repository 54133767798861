import '../authstate-context';
import '../link-to-element';
import '../main-layout';
import '../main-header';
import '../main-footer';
import {pages} from '../constants-enumerate';

export default (ctx, html) => html`
  <main-layout full-height>
    <main-header dom-scope="T0sn4QcJ" role="banner" slot="page-header">
      <a dom-scope="fkUTeyN0" id="skip-navigation-link" href="#home-btn" class="enteractive" @click="${(e) => ctx.skipNavTo(e)}" slot="skip-navigation" aria-label="Skip to Main Content" tabindex="0"><div class="montserrat">Skip Navigation</div></a>

      <authstate-context slot="right">
        <link-to dom-scope="RGjxhWIk" href="${pages.LOGIN}" class="header-button login-button" tab-outline-blue><span aria-label="Log In to Homeschool Plus">Log In</span></link-to>
      </authstate-context>

      <authstate-context slot="right" show-when-loggedin>
        <button dom-scope="6zLLwDUu" id="logout-button" class="header-button logout-button tab-outline-blue enteractive" @click="${(e) => ctx.logOut(e)}"><span aria-label="Log out of Homeschool Plus">Log Out</span></button>
      </authstate-context>
    </main-header>
    <main id="page-content" slot="page-content">
      <div id="text">
        <p>404</p>
        <p>Page Not Found</p>
      </div>
      <div id="home-btn" class="button-wrapper enteractive tab-outline-blue" role="button" type="submit" @click="${(e) => ctx.goHomeClick(e)}" tabindex="0" aria-label="Home">
        <button dom-scope="IkOYV+q2" id="home-link" class="button" tabindex="-1" aria-hidden="true">HOME</button>
      </div>
    </main>
    <main-footer dom-scope="qM/dqccP" id="main-footer" slot="page-footer"></main-footer>
  </main-layout>
`;
