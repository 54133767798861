import {cacheNamespaces} from './constants-enumerate';
import {userInfoSdo} from './sdo-user-info';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';

class UserInfoService {
  /**
   *
   * @static
   */
  static setUserInfo(apiResp) {
    this.setUserSession(apiResp);
    this.setUserState(apiResp);
  }
  /**
   *
   * @static
   */
  static setUserSession(apiResp) {
    const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
    userSessionData.setItem(cacheNamespaces.USER_SESSION, {
      userToProductUuid: apiResp?.payload?.user_to_product_uuid, /* eslint-disable-line */
      accountStatus: apiResp?.payload?.account_status, /* eslint-disable-line */
      maskedEmail: apiResp?.payload?.masked_email, /* eslint-disable-line */
      userMetadata: apiResp?.payload?.user_meta_data, /* eslint-disable-line */
      paymentProvider: apiResp?.payload?.payment_provider, /* eslint-disable-line */
      regPathKey: apiResp?.payload?.user_meta_data?.subscription_path, /* eslint-disable-line */
      completedStep: apiResp?.payload?.user_meta_data?.completed_step, /* eslint-disable-line */
      debugUser: apiResp?.payload?.debug_user /* eslint-disable-line */
    });
  }
  /**
   *
   * @static
   */
  static setUserState(apiResp) {
    userInfoSdo.userInfo = {
      userToProductUuid: apiResp?.payload?.user_to_product_uuid, /* eslint-disable-line */
      accountStatus: apiResp?.payload?.account_status, /* eslint-disable-line */
      maskedEmail: apiResp?.payload?.masked_email, /* eslint-disable-line */
      userMetadata: apiResp?.payload?.user_meta_data, /* eslint-disable-line */
      paymentProvider: apiResp?.payload?.payment_provider, /* eslint-disable-line */
      regPathKey: apiResp?.payload?.user_meta_data?.subscription_path,
      completedStep: apiResp?.payload?.user_meta_data?.completed_step,
      debugUser: apiResp?.payload?.debug_user /* eslint-disable-line */
    };
  }
  /**
   *
   * @static
   */
  static clear() {
    const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
    userSessionData.clear();
    userInfoSdo.reset();
  }
}

export {
  UserInfoService
};
