import {routerInstance} from '@aofl/router';
import {AppCallService} from '../../modules/app-call-service';
import {environment} from '../../modules/constants-enumerate';

/**
 * @private
 * @type {RegExp}
 */
const EXTERNAL_LINK_REGEX = /^https?:\/\//;

const createLinkToConditions = (ctx) => {
  const hasTargetOnly = !ctx.showParentGate && ctx.target;
  const isExternalURL = EXTERNAL_LINK_REGEX.test(ctx.href);

  return {
    inAppOnly: {
      condition: () => {
        return environment.IN_APP;
      },
      resolve: () => {
        const origin = window.location.origin;
        let url = origin + ctx.href;

        if (/https/.exec(ctx.href) !== null) {
          url = ctx.href;
        }

        const appPayload = {
          uri: url,
          target: ctx.target
        };

        AppCallService.openUri(appPayload);
      }
    },
    defaultTargetOnly: {
      condition: () => {
        return hasTargetOnly;
      },
      resolve: () => {
        window.open(ctx.href, ctx.target);
      }
    },
    defaultNoTargetExternalURL: {
      condition: () => {
        return !ctx.target && isExternalURL;
      },
      resolve: () => {
        window.location = ctx.href;
      }
    },
    ctrlClick: {
      condition: () => {
        return ctx.ctrlClick;
      },
      resolve: () => {
        window.open(ctx.href, '_blank');
      }
    },
    defaultNoTarget: {
      condition: () => {
        return true;
      },
      resolve: () => {
        routerInstance.navigate(ctx.href);
      }
    }
  };
};

export {
  createLinkToConditions
};
