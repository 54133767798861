import '../../modules/gdpr-banner';

export default (ctx, html) => html`
  <div><slot name="header">
    <div class="${ctx.headerOverlay ? 'show-overlay' : ''}"><slot name="home-header"></slot></div>
    <div class="${ctx.headerOverlay ? 'show-overlay' : ''}"><slot name="page-header"></slot></div>
  </slot></div>
  <div class="${ctx['full-height'] ? 'full-height' : ''} ${ctx.contentOverlay ? 'show-overlay' : ''}"><slot name="page-content"></slot></div>
  <div class="${ctx.footerOverlay ? 'show-overlay' : ''}"><slot name="page-footer"></slot></div>
  <gdpr-banner></gdpr-banner>
`;
