import {ClickTracker} from '@aoflmkt/tracker-queue';
import {eventNames} from './constants-enumerate';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import {uuid} from '@aofl/uuid';
import clickTrackerConfig from './__config/click-tracker';
import {userInfoSdo} from './sdo-user-info';

const clickTrackerMixin = (superClass) => {
  /**
   * @summary ClickTrackerMixin
   * @extends {superClass}
   */
  class ClickTrackerMixin extends superClass {
    /**
     * constructor
     */
    constructor(...args) {
      super(...args);
      this.trackerId = uuid();
    }
    /**
     *
     * @memberof ClickTrackerMixin
     */
    connectedCallback(...args) {
      super.connectedCallback(...args);

      const hasUserToProductUuid = userInfoSdo?.userInfo?.userToProductUuid !== '';

      marketingTrackerQueue
        .track(this.trackerId, new ClickTracker(clickTrackerConfig, (e, domScope) => {
          if (domScope) {
            marketingTrackerQueue.enqueueEvent({
              event_type: eventNames.LINK_CLICK,
              event_payload: {
                aofl_product: 'home_school',
                link_info: {
                  site_section: 'regpath',
                  page_name: this.localName,
                  link_id: domScope
                }
              },
              ...(hasUserToProductUuid && {user_to_product_uuid: userInfoSdo?.userInfo?.userToProductUuid})
            });
          }
        }, this.shadowRoot));
    }
    /**
     *
     * @param {any} args
     * @memberof ClickTrackerMixin
     */
    disconnectedCallback(...args) {
      // marketingTrackerQueue.untrack(this.trackerId);

      super.disconnectedCallback(...args);
    }
  }

  return ClickTrackerMixin;
};

export {
  clickTrackerMixin
};
