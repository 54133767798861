import {cookies, queryParamKeys, eventNames} from './constants-enumerate';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import Cookies from 'js-cookie';

/**
 *
 * @author Marco Sanchez <marco.sanchez@aofl.com>
 */
class CampaignService {
  /**
   *
   * @param {String} campaignId
   */
  static attribute(campaignId) {
    if (campaignId) {
      campaignId = decodeURIComponent(campaignId);
      const CAMPAIGN_FORMAT_REGEX = /[^\s\t\n:([{]+:[^\s\t\n)\]}]+/i;
      const match = campaignId.match(CAMPAIGN_FORMAT_REGEX);
      if (match !== null) {
        campaignId = match[0];
      }
      const campaignInfo = CampaignService.setCampaignCookie(campaignId);
      return CampaignService.fireCampaignClickEvent(campaignInfo);
    }
  }

  /**
   *
   * @param {String} campaignId
   * @return {Object}
   */ker
  static setCampaignCookie(campaignId) {
    const campaignInfo = {
      'id': campaignId,
      'values': CampaignService.getCampaignValues()
    };

    const cookieConfig = {
      ...cookies.CONFIG,
      'expires': 30
    };
    Cookies.set(cookies.CAMPAIGN_INFO, JSON.stringify(campaignInfo), cookieConfig);
    return campaignInfo;
  }

  /**
   *
   * @param {Object} campaignInfo
   */
  static fireCampaignClickEvent(campaignInfo) {
    const campaignClickEvent = {
      event_type: eventNames.CAMPAIGN_CLICK,
      event_payload: {
        aofl_product: 'home_school',
        source_info: {
          source_tag: campaignInfo.id,
          dynamic_info: campaignInfo.values
        }
      }
    };

    marketingTrackerQueue.enqueueEvent(campaignClickEvent);
  }

  /**
   *
   * @return {Object}
   */
  static getCampaignValues() {
    const campaignValues = {};
    const QUERY_STRING_REGEX = /[^=?&]+=[^&]+/g;
    const PARAMETER_REGEX = /([^=?&]+)=([^&]+)/;
    const parameters = location.search.match(QUERY_STRING_REGEX);

    if (parameters !== null) {
      parameters.forEach((element) => {
        const match = element.match(PARAMETER_REGEX);
        if (match !== null && match[1].toLowerCase() !== queryParamKeys.SOURCE_TAG) {
          campaignValues[match[1]] = decodeURIComponent(match[2]);
        }
      });
    }
    return campaignValues;
  }

  /**
   *
   * @return {Object}
   */
  static getCampaignInfo() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sourceTag = urlSearchParams.get(queryParamKeys.SOURCE_TAG);

    let campaignInfo = {
      'id': null,
      'values': []
    };

    if (sourceTag) {
      campaignInfo.id = sourceTag;
    } else {
      const campaignCookie = Cookies.get(cookies.CAMPAIGN_INFO);

      if (campaignCookie) {
        try {
          campaignInfo = JSON.parse(campaignCookie);
        } catch (err) {}
      }
    }

    return campaignInfo;
  }
}

export {
  CampaignService
};
