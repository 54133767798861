import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';

/**
 * @summary MainHeader
 * @extends {clickTrackerMixin(accessibilityMixin(AoflElement))}
 */
@customElement('main-header')
class MainHeader extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * @readonly
   */
  static is = 'main-header';

  @property({type: Boolean, attribute: 'prevent-redirect'})
  preventRedirect = false;

  @property({type: Boolean, attribute: 'prevent-redirect-alt'})
  preventRedirectAlt = false;

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainHeader;
