import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const imageConfig = {
  homeschoolPlusLogo: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/templates/main-header/homeschool-plus-logo-mb-v2-1x.png' : '/assets/templates/main-header/homeschool-plus-logo-mb-v2-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/templates/main-header/homeschool-plus-logo-mb-v2-2x.png' : '/assets/templates/main-header/homeschool-plus-logo-mb-v2-2x.webp'}`},
    {maxWidth: 568, src: `${isNotQualified ? '/assets/templates/main-header/homeschool-plus-logo-mb-v2-3x.png' : '/assets/templates/main-header/homeschool-plus-logo-mb-v2-3x.webp'}`},
    {maxWidth: 640, src: `${isNotQualified ? '/assets/templates/main-header/homeschool-plus-logo-dt-v2-1x.png' : '/assets/templates/main-header/homeschool-plus-logo-dt-v2-1x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/templates/main-header/homeschool-plus-logo-dt-v2-2x.png' : '/assets/templates/main-header/homeschool-plus-logo-dt-v2-2x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/templates/main-header/homeschool-plus-logo-dt-v2-3x.png' : '/assets/templates/main-header/homeschool-plus-logo-dt-v2-3x.webp'}`}
  ],
  homeschoolPlusTextLogo: [
    {maxWidth: 320, src: `${isNotQualified ? '/assets/templates/main-header/aofl-hsplus-logo-mb-v1-1x.png' : '/assets/templates/main-header/aofl-hsplus-logo-mb-v1-1x.webp'}`},
    {maxWidth: 480, src: `${isNotQualified ? '/assets/templates/main-header/aofl-hsplus-logo-mb-v1-2x.png' : '/assets/templates/main-header/aofl-hsplus-logo-mb-v1-2x.webp'}`},
    {maxWidth: 568, src: `${isNotQualified ? '/assets/templates/main-header/aofl-hsplus-logo-mb-v1-3x.png' : '/assets/templates/main-header/aofl-hsplus-logo-mb-v1-3x.webp'}`},
    {maxWidth: 640, src: `${isNotQualified ? '/assets/templates/main-header/aofl-hsplus-logo-dt-v1-1x.png' : '/assets/templates/main-header/aofl-hsplus-logo-dt-v1-1x.webp'}`},
    {maxWidth: 768, src: `${isNotQualified ? '/assets/templates/main-header/aofl-hsplus-logo-dt-v1-2x.png' : '/assets/templates/main-header/aofl-hsplus-logo-dt-v1-2x.webp'}`},
    {maxWidth: 1024, src: `${isNotQualified ? '/assets/templates/main-header/aofl-hsplus-logo-dt-v1-3x.png' : '/assets/templates/main-header/aofl-hsplus-logo-dt-v1-3x.webp'}`}
  ],
};

export {
  imageConfig
};
