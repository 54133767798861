import '../../modules/link-to-element';
import '../../modules/modal-close-button';
import {pages} from '../../modules/constants-enumerate';

export default (ctx, html) => html`
<div id="banner-content">
   <modal-close-button dom-scope="pkUaa8CF" id="modal-close-button" slot="modal-close-button" @click=${ctx.hideBanner} black-button></modal-close-button>

  <p>We use cookies to deliver the best possible experience on our website and to analyse usage.</p>

  <p>Our <link-to dom-scope="LaQ9/Gig" class="underlined-link" href="${pages.COOKIE_POLICY}" target="_blank" use-parent-gate tab-outline-blue>Cookie Policy</link-to> provides further information on our cookie uses and how to amend your cookie settings.</p>
  <p>If you continue to use our website we will assume you are ok with our use of cookies.</p>
</div>
`;
