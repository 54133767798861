import {isMobile} from '@aoflmkt/is-mobile';
import {routerInstance} from '@aofl/router';
import {ApiService} from './api-service';
import {AppCallService} from './app-call-service';
import {environment, pages, localStorageKeys, regStepsEnumerate} from './constants-enumerate';
import {userInfoSdo} from './sdo-user-info';
import {registrationPathSteps} from './__config/registration-path-steps';
import {resolveCondition} from '../modules/page-load-utils';

/**
 *
 * @class RegistrationPathService
 */
class RegistrationPathService {
  /**
   *
   * @static
   * @return {Object}
   */
  static get userSession() {
    return {
      userToProductUuid: userInfoSdo?.userInfo?.userToProductUuid || '',
      accountStatus: userInfoSdo?.userInfo?.accountStatus || '',
      userPaymentProvider: userInfoSdo?.userInfo?.userPaymentProvider || '',
      userMetadata: userInfoSdo?.userInfo?.userMetadata || {},
      regPathKey: userInfoSdo?.userInfo?.userMetadata?.subscription_path || '',
      completedStep: userInfoSdo?.userInfo?.completedStep || '',
      debugUser: userInfoSdo?.userInfo?.debugUser || '',
      isLoggedIn: userInfoSdo.isLoggedIn,
      token: userInfoSdo.token,
      emailAddress: userInfoSdo.emailAddress,
      password: userInfoSdo.password
    };
  }

  /**
   *
   * @static
   */
  static async validateLogin() {
    try {
      const resp = await ApiService.validateLogin();
      userInfoSdo.isLoggedIn = resp?.payload?.token_valid; // eslint-disable-line
      userInfoSdo.token = resp?.payload?.token;
    } catch (e) {}
  }

  /**
   *
   * @static
   * @return {Function}
   */
  static async next() {
    await this.validateLogin();

    const redirectCondition = this.getRedirectCondition();
    const resolvedCondition = resolveCondition(redirectCondition);
    return resolvedCondition.resolve();
  }

  /**
   * @static
   */
  static getNextStep() {
    const {completedStep, regPathKey} = this.userSession;

    if (registrationPathSteps.hasOwnProperty(regPathKey)) { // eslint-disable-line
      const steps = registrationPathSteps[regPathKey];

      if (!completedStep) {
        // First time subscriber, on first step
        return steps[0];
      }

      for (let i = 0; i < steps.length; i++) {
        if (steps[i] === completedStep) {
          return steps[i+1];
        }
      }
    } else {
      return pages.SUBSCRIPTION;
    }
  }

  /**
   *
   * @static
   */
  static makeLoginAppCall() {
    const appPayload = {
      token: localStorage.getItem(localStorageKeys.APP_TOKEN),
    };

    AppCallService.login(appPayload);
  }

  /**
   *
   * @param {*} step
   */
  static async markStepComplete(step) {
    await ApiService.getCompletedStep(step);
    userInfoSdo.setUserCompletedStep(step);
  }

  /**
   * @static
   */
  static async getUpgradeEligibility() {
    // call getUpgradeEligibility endpoint to see if eligible.
    const getUpgradeEligibilityResp = await ApiService.getUpgradeEligibility();
    const isUpgradeEligible = getUpgradeEligibilityResp?.payload?.is_upgrade_eligible;
    return isUpgradeEligible;
  }

  /**
   * @static
   */
  static getRedirectCondition() {
    const {isLoggedIn, debugUser} = this.userSession;
    const nextStep = this.getNextStep();

    return {
      inApp: {
        condition: () => {
          return environment.IN_APP && !environment.IS_IOS;
        },
        resolve: () => this.makeLoginAppCall()
      },
      inAppIos: {
        condition: () => {
          return environment.IN_APP && environment.IS_IOS && nextStep === regStepsEnumerate.LAST_STEP;
        },
        resolve: () => this.makeLoginAppCall()
      },
      isLoggedInAndDebuggerOrDesktop: {
        condition: () => {
          return (isLoggedIn && (debugUser || (nextStep === regStepsEnumerate.LAST_STEP && !isMobile.any)));
        },
        resolve: () => (window.location = '/app')
      },
      isLoggedInAndDownloadAppPage: {
        condition: () => {
          return (isLoggedIn && (nextStep === regStepsEnumerate.LAST_STEP && isMobile.any));
        },
        resolve: () => routerInstance.navigate(pages.DOWNLOAD_APP)
      },
      isLoggedIn: {
        condition: () => {
          return isLoggedIn;
        },
        resolve: () => routerInstance.navigate(pages[nextStep], {})
      },
      notLoggedIn: {
        condition: () => {
          return !isLoggedIn;
        },
        resolve: () => routerInstance.navigate(pages.LOGIN)
      }
    };
  }
}

export {
  RegistrationPathService
};
