import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {cookies, environment} from '../constants-enumerate';
import {ModalService} from '../modal-service';
import Cookies from 'js-cookie';

/**
 * @summary MainFooter
 * @extends {clickTrackerMixin(accessibilityMixin(AoflElement))}
 */
@customElement('main-footer')
class MainFooter extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * @readonly
   */
  static is = 'main-footer';

  @property({type: Number})
  currentYear = (new Date()).getFullYear();

  @property({type: Boolean, attribute: 'show-logout'})
  showLogout = false;

  @property({type: Boolean})
  showDoNotSellMyInfo = ((Cookies.get(cookies.COUNTRY_CODE) === 'US') && (Cookies.get(cookies.REGION) === 'CA'));

  @property({type: Boolean, attribute: 'show-accessibility'})
  showAccessibility = false;

  @property({type: Boolean})
  useInAppLinks = environment.IN_APP || Cookies.get(cookies.LINK_FROM_APP);

  /**
   *
   */
  showCookieConsentModal(e) {
    e.preventDefault();
    ModalService.show('cookieConsentModal');
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainFooter;
