import {regStepsEnumerate, regPathKeys} from '../constants-enumerate';

const registrationPathSteps = {
  [regPathKeys.DEFAULT_SUBSCRIBER]: [
    regStepsEnumerate.SURVEY,
    regStepsEnumerate.LAST_STEP
  ],
  [regPathKeys.DEFAULT_RESUBSCRIBER]: [
    regStepsEnumerate.SURVEY,
    regStepsEnumerate.LAST_STEP
  ]
};

export {
  registrationPathSteps
};
