import {PathUtils} from '@aofl/router';
import {uuid} from '@aofl/uuid';
import {cookies, localStorageKeys, queryParamKeys} from './constants-enumerate';
import Cookies from 'js-cookie';

const getLinkFromApp = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let linkFromApp = urlSearchParams.get(queryParamKeys.LINK_FROM_APP);
  linkFromApp = PathUtils.removeTrailingSlash(linkFromApp);
  if (linkFromApp && linkFromApp) {
    Cookies.remove(cookies.LINK_FROM_APP, {
      secure: cookies.CONFIG.secure
    });
    Cookies.set(cookies.LINK_FROM_APP, linkFromApp, cookies.CONFIG);
  }
};

const getAppStore = () => {
  if (Cookies.get(cookies.APP_STORE)) return;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const appStore = urlSearchParams.get(queryParamKeys.APP_STORE);
  Cookies.set(cookies.APP_STORE, appStore, cookies.CONFIG);
};

const getDeviceId = () => {
  getLinkFromApp();
  getAppStore();
  let savedDeviceId = '';
  if (localStorage.getItem(localStorageKeys.DEVICE_ID)) {
    savedDeviceId = localStorage.getItem(localStorageKeys.DEVICE_ID);
  }

  if (typeof savedDeviceId !== 'undefined' && savedDeviceId !== '') return savedDeviceId;

  const deviceId = uuid();
  localStorage.setItem(localStorageKeys.DEVICE_ID, `"${deviceId}"`);

  return deviceId;
};

export {
  getDeviceId
};
