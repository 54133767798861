import {CaptchaService} from '../captcha-service';
import {aoflProducts, localStorageKeys, environment, platforms, queryParamKeys, cookies} from '../constants-enumerate';
import {getDeviceId} from '../device';
import Cookies from 'js-cookie';

/**
 *
 *
 * @class MarketingJsonFormatter
 */
class MarketingJsonFormatter {
  /**
   *
   * @static
   * @param {Object} payload
   * @return {Object}
   */
  static pack({args}) {
    const body = new FormData();
    const reqHeaders = new Headers();
    const deviceId = getDeviceId();
    const platform = environment.IS_IOS ? platforms.IOS_APP: platforms.DEFAULT;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const appStore = urlSearchParams.get(queryParamKeys.APP_STORE) || Cookies.get(cookies.APP_STORE)?.toLowerCase();

    body.append('arguments', JSON.stringify(args) || null);
    body.append('appStore', appStore || 'n/a');
    body.append('platform', platform);
    body.append('device_id', deviceId?.replace(/['"]+/g, ''));
    body.append('client_host', location.origin); // only send in dev (if client is on qat or localhost)
    body.append('aofl_product_uuid', aoflProducts.HOMESCHOOL_PRODUCT_UUID);

    const aoflToken = localStorage.getItem(localStorageKeys.AOFL_TOKEN);
    if (aoflToken !== null) {
      reqHeaders.append('Aofl-Token', aoflToken?.replace(/['"]+/g, ''));
    }

    return {
      method: 'POST',
      body,
      credentials: 'include',
      cache: 'no-cache',
      headers: reqHeaders
    };
  }

  /**
   *
   * @static
   * @param {Object} response
   * @return {Object}
   */
  static async unpack(response) {
    const data = await response.json();
    const aoflToken = response.headers.get('aofl-token');

    if (response.status === 403 && typeof data.payload === 'undefined') {
      data.captcha = true;
      try {
        CaptchaService.parseCaptchaResponse(data);
      } catch (e) {
      }
    }

    if (data.success === 'TRUE') {
      if (aoflToken) localStorage.setItem(localStorageKeys.AOFL_TOKEN, `"${aoflToken}"`);
      if (data?.payload?.token) localStorage.setItem(localStorageKeys.APP_TOKEN, `"${data?.payload?.token}"`);
      return data;
    }

    return Promise.reject(data);
  }
}

export default MarketingJsonFormatter;
