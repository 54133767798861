import styles from './template.css';
import template from './template';
import {AoflElement, property, customElement} from '@aofl/element';
import {routerInstance, PathUtils} from '@aofl/router';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {resolveCondition} from '../page-load-utils';
import {createLinkToConditions} from '../__config/link-to-conditions';

/**
 * @extends {clickTrackerMixin(AoflElement)}
 */
@customElement('link-to')
class LinkToElement extends clickTrackerMixin(AoflElement) {
  /**
   * @type {String}
   * @readonly
   */
  static is = 'link-to';

  /**
   * @return {Object}
   */
  @property({type: String, attribute: true})
  href = '';

  @property({type: String, attribute: true})
  target = '';

  /**
   * @type {Boolean}
   */
  @property({type: Boolean})
  ['disabled'] = false;

  /**
   * @type {Boolean}
   */
  @property({type: Boolean})
  ['track-url'] = false;

  /**
   * @type {Boolean}
   */
  @property({type: Boolean})
  ['track-url-partial'] = false;

  @property({type: Boolean, attribute: 'tab-focus-disabled'})
  'tabFocusDisabled' = false;

  @property({type: Boolean})
  'tab-outline-blue' = false;

  @property({type: Boolean})
  'tab-outline-white' = false;

  /**
   * @private
   * @type {Function}
   */
  routerUnsubscribe = () => {};

  /**
   * @private
   * @type {Boolean}
   */
  ctrlClick = false;

  /**
   * @param {Event} e
   * @return {void}
   */
  clickHandler(e) {
    e.preventDefault();
    const linkToConditions = createLinkToConditions(this);
    const resolvedLink = resolveCondition(linkToConditions);
    resolvedLink.resolve();
  }

  /**
   * @private
   * @type {Function}
   * @param {Event} e
   */
  keydownHandler = (e) => {
    if (e.which === 17 || e.which === 91) {
      this.ctrlClick = true;
      window.addEventListener('keyup', this.keyupHandler);
    }
  };

  /**
   * @private
   * @type {Function}
   * @param {Event} e
   */
  keyupHandler = (e) => {
    if (e.which === 17 || e.which === 91) {
      this.ctrlClick = false;
      window.addEventListener('keyup', this.keyupHandler);
    }
  };

  /**
   * @private
   * @type {Function}
   * @param {Event} e
   */
  focusHandler = (e) => {
    this.ctrlClick = false;
  }

  /**
   * Check if path matches href.
   *
   * @private
   * @param {String} path
   */
  checkActive(path) {
    const cleanHref = PathUtils.removeTrailingSlash(PathUtils.cleanPath(this.href)) + '/';
    const cleanPath = PathUtils.removeTrailingSlash(PathUtils.cleanPath(path)) + '/';

    if (cleanHref === cleanPath) {
      this.classList.add('active');
    } else if (this['track-url-partial'] && cleanPath.indexOf(cleanHref) === 0) {
      this.classList.add('active');
    } else {
      this.classList.remove('active');
    }
  }

  /**
   *
   */
  firstUpdated() {
    super.firstUpdated();

    if (this['tab-outline-blue']) {
      this.classList.add('tab-outline-blue');
    } else {
      this.classList.remove('tab-outline-blue');
    }

    if (this['tab-outline-white']) {
      this.classList.add('tab-outline-white');
    } else {
      this.classList.remove('tab-outline-white');
    }
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('keydown', this.keydownHandler);
    window.addEventListener('focus', this.focusHandler);
    if (this['track-url'] || this['track-url-partial']) {
      this.checkActive(location.pathname);
      this.routerUnsubscribe = routerInstance.after((request, response, next) => {
        this.checkActive(response.to);
        next(response);
      });
    }
  }

  /**
   *
   */
  disconnectedCallback() {
    window.removeEventListener('keyup', this.keyupHandler);
    window.removeEventListener('keydown', this.keydownHandler);
    window.removeEventListener('focus', this.focusHandler);
    this.routerUnsubscribe();
    super.disconnectedCallback();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default LinkToElement;
