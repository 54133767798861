export default [
  {
    tagName: 'A',
    parents: [
      'MAIN-HEADER',
      'MAIN-FOOTER',
    ]
  },
  {
    tagName: 'LINK-TO',
    parents: [
      'MAIN-HEADER',
      'MAIN-FOOTER',
    ]
  },
  {
    tagName: 'MODAL-CLOSE-BUTTON'
  },
  {
    tagName: 'LOGOUT-BUTTON'
  },
  {
    tagName: 'BUTTON'
  }
];
