import '../link-to-element';
import {cookies, pages} from '../constants-enumerate';
import Cookies from 'js-cookie';

const isAU = Cookies.get(cookies.COUNTRY_CODE) === 'AU';

export default (ctx, html) => html`
  <slot></slot>
  <footer>
    <div id="trademark">
      ${Cookies.get(cookies.LINK_FROM_APP) ? html`
        TM &amp; &copy; 2007-${ctx.currentYear} Age of Learning, Inc. Patents Pending
      ` : html`
        TM &amp; &copy; 2007-${ctx.currentYear} <link-to dom-scope="d9t34c/c" id="aofl-link" class="link" href="https://www.ageoflearning.com/about-us/" target="_blank" rel="noopener noreferrer" tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Age of Learning, Inc.</span></link-to> Patents Pending
      `}
    </div>
    <div id="links">
      <span class="link" id="tandc">
        <link-to dom-scope="YkUn2oB7" href="${ctx.useInAppLinks ? pages.TERMS_CONDITIONS_LINK_FROM_APP : pages.TERMS_CONDITIONS}" target="_blank" rel="noopener noreferrer" tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Terms &amp; Conditions</span></link-to>
      </span>

      ${isAU ? html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="COZmchiQ" href="${ctx.useInAppLinks ? pages.PRIVACY_POLICY_LINK_FROM_APP : pages.PRIVACY_POLICY}" target="_blank" rel="noopener noreferrer" tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Australian Privacy Statement &amp; Privacy Policy</span></link-to>
        </span>
      ` : html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="mS50fN32" href="${ctx.useInAppLinks ? pages.PRIVACY_POLICY_LINK_FROM_APP : pages.PRIVACY_POLICY}" target="_blank" rel="noopener noreferrer" tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Privacy Policy</span></link-to>
        </span>
      `}

      ${ctx.showPrivacyPortal ? html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="XeqHvHF7" target="_blank" tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Privacy Portal</span></link-to>
        </span>
      ` : ''}

      ${ctx.showDoNotSellMyInfo ? html `
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <a dom-scope="jE6caIpl" class="enteractive tab-outline-blue link" role="link" @click="${(e) => ctx.showCookieConsentModal(e)}" tabindex="0">Do Not Sell My Personal Information</a>
        </span>
      ` : ''}

      <span class="u-d-hide"></span>

      <span class="divider mobile-no-divider">|</span>
      <span class="link">
        <link-to dom-scope="FWTVytVS" target="_blank" href="${pages.CUSTOMER_SUPPORT}" tab-focus-disabled><span class="enteractive tab-outline-blue rm-tabindex" tabindex="0">Customer Support</span></link-to>
      </span>

      ${ctx.showAccessibility ? html`
        <span class="divider" aria-hidden="true">|</span>
        <span class="link">
          <link-to dom-scope="VG6/sj4U" href="${ctx.useInAppLinks ? pages.ACCESSIBILITY_STATEMENT_LINK_FROM_APP : pages.ACCESSIBILITY_STATEMENT}" target="_blank" aria-label="ABCmouse Accessibility Statement" tab-outline-blue>Accessibility Statement</link-to>
        </span>
      ` : ''}
    </div>
  </footer>
`;
