import {uuid} from '@aofl/uuid';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {appCalls, localStorageKeys} from './constants-enumerate';

const storage = new CacheManager(uuid(), cacheTypeEnumerate.MEMORY, 0);
const responseHandler = (event) => {
  if (typeof event !== 'object' || typeof event.data === 'undefined') return;
  const data = event.data;
  const callbacks = storage.getCollection();

  for (const key in callbacks) {
    if (callbacks.hasOwnProperty(key)) { // eslint-disable-line
      callbacks[key](data);
    } else {} // eslint-disable-line
  }
};

/**
 *
 */
class AppCallService {
  /**
   *
   *
   * @readonly
   * @static
   */
  static get storage() {
    return storage;
  }

  /**
   *
   * @param {String} _schema
   * @param {Object} _params
   */
  static send(appCall, payload) {
    window.FlutterCallback.postMessage(JSON.stringify({
      request: appCall,
      payload
    }));
  }

  /**
   *
   * @param {Function} callback
   * @return {Function}
   */
  static addListener(callback) {
    if (typeof callback !== 'function') return;

    const actionKey = String(callback);
    storage.setItem(actionKey, callback);

    if (storage.size === 1) {
      window.addEventListener('message', responseHandler);
    }
    return () => {
      storage.removeItem(actionKey);
      if (storage.size === 0) {
        window.removeEventListener('message', responseHandler);
      }
    };
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.token
   */
  static login(payload) {
    this.send(appCalls.LOGIN, {
      token: (payload.token).replace(/['"]+/g, ''), // eslint-disable-line
    });
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.uri
   * @param {String} [target='']
   */
  static openUri(payload) {
    this.send(appCalls.OPEN_URI, {
      uri: payload.uri, // eslint-disable-line
      target: payload.target
    });
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.type
   * @param {String} payload.request
   */
  static logEvent(payload) {
    payload.customParams.device_id = localStorage.getItem(localStorageKeys.DEVICE_ID);

    this.send(appCalls.EVENT, {
      eventType: payload.type,
      customParams: JSON.stringify(payload.customParams),
      requestCallbackKey: payload.request
    });
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.product
   * @param {String} payload.store
   * @param {String} payload.request
   */
  static purchase(payload) {
    this.send(appCalls.PURCHASE, {
      product: payload.product,
      store: payload.store,
      requestCallbackKey: payload.request
    });
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.store
   * @param {String} payload.request
   */
  static restorePurchase(payload) {
    this.send(appCalls.RESTORE_PURCHASE, {
      store: payload.store,
      requestCallbackKey: payload.request
    });
  }
}

export {
  AppCallService
};
