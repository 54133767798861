// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-BoldIt.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-Light.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-LightIt.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-RegularIt.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-Semibold.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../../fonts/abcmouse-sans-web/ABCmouseSans-SemiboldIt.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:700;font-style:italic;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:100;font-style:normal;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:100;font-style:italic;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:300;font-style:italic;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:abcmouseSans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");font-weight:500;font-style:italic;font-display:swap}.poppins{font-family:Poppins,Arial,Helvetica,sans-serif}.arial{font-family:Arial,Helvetica,sans-serif}", ""]);
// Exports
module.exports = exports;
