import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {storeInstance} from '@aofl/store';
import {clickTrackerMixin} from '../../modules/click-tracker-mixin';
import {accessibilityMixin} from '../../modules/accessibility-mixin';
import {mapStatePropertiesMixin} from '@aofl/map-state-properties-mixin';
import {cookies} from '../../modules/constants-enumerate';
import {gdprBannerSdo} from './gdpr-banner-sdo';
import Cookies from 'js-cookie';

/**
 * @summary GdprBanner
 * @class GdprBanner
 * @extends {AoflElement}
 */
@customElement('gdpr-banner')
class GdprBanner extends mapStatePropertiesMixin(clickTrackerMixin(accessibilityMixin((AoflElement)))) {
  /**
   * Creates an instance of GdprBanner.
   */
  constructor() {
    super();
    this.storeInstance = storeInstance;
  }

  /**
   *
   * @readonly
   */
  static is = 'gdpr-banner';

  /**
   *
   * @private
   */
  hideBanner(e) {
    e.preventDefault();
    Cookies.set(cookies.GDPR, 'hide', {
      secure: cookies.CONFIG.secure,
      domain: cookies.CONFIG.domain,
      expires: 90
    });
    gdprBannerSdo.visible = false;
  }

  /**
   *
   * @private
   */
  mapStateProperties() {
    this.style.display = gdprBannerSdo.visible ? 'block' : 'none';
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default GdprBanner;
