import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {uuid} from '@aofl/uuid';
import {ApiService} from './api-service';
import {CampaignService} from './campaign-service';
import {cacheNamespaces, cookies, eventNames, localStorageKeys} from './constants-enumerate';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import Cookies from 'js-cookie';

const cacheManager = new CacheManager(cacheNamespaces.CAMPAIGN_PIXELS_LOG, cacheTypeEnumerate.LOCAL);

/**
 * Service to fire pixels using an iframe
 * @class
 */
class PixelService {
  /**
   *
   * @param {String} event (landing|conversion|sign-up)
   * @return {Promise}
   */
  static async fire(event, replacements = {}) {
    if (navigator.globalPrivacyControl || Cookies.get(cookies.WDIO_SUPPRESS_PIXELS)) return;

    try {
      const conversionEventID = uuid();
      replacements.event_id = conversionEventID;
      localStorage.setItem(localStorageKeys.CONVERSION_EVENT_ID, conversionEventID);

      await PixelService.fireInternalPixel(event, replacements);
      PixelService.fireGlobalPixel(event);
      await PixelService.fireCampaignPixel(event);
    } catch (error) {
    }
  }

  /**
   *
   * @param {String} event
   * @return {Function}
   */
  static trackEvent(action, event, type) {
    const campaignId = CampaignService.getCampaignInfo()?.id;

    const trackerPayload = {
      event_type: `${eventNames.PIXEL_FIRE}-${action}`,
      event_payload: {
        aofl_product: 'home_school',
        event,
        type,
        campaignId
      }
    };

    return marketingTrackerQueue.enqueueEvent(trackerPayload);
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static fireGlobalPixel(event) {
    if (navigator.globalPrivacyControl) return;
    PixelService.setGtmEventMetadata(event);
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireInternalPixel(event, replacements) {
    const {pixelsInternal} = await import('./__config/pixels');

    if (pixelsInternal[event]) {
      const internalPixels = pixelsInternal[event];

      for (let i = 0; i < internalPixels.length; i++) {
        for (const key in replacements) {
          if (replacements.hasOwnProperty(key)) { // eslint-disable-line
            const regex = new RegExp('{{' + key + '}}', 'gi');
            internalPixels[i] = internalPixels[i].replace(regex, replacements[key]);
          }
        }

        await fetch(internalPixels[i], { // eslint-disable-line
          method: 'GET',
          headers: {
            'Content-type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
          },
          mode: 'no-cors'
        });
      }

      PixelService.trackEvent(event);
    }
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireCampaignPixel(event) {
    if (navigator.globalPrivacyControl) return;
    const campaignInfo = CampaignService.getCampaignInfo();
    if (campaignInfo.id !== null && !PixelService.hasPixelFire(campaignInfo.id, event)) {
      const payload = {
        event,
        campaignInfo
      };
      const response = await ApiService.resolvePixelInfo(payload);

      if (response.payload && response.payload.pixel) {
        PixelService.trackEvent('before', event, 'campaign');
        await PixelService.attachPixel(response.payload.pixel);
        PixelService.trackEvent('after', event, 'campaign');
        PixelService.logPixel(campaignInfo.id, event);
      }
    }
  }

  /**
   *
   * @param {String} campaignId
   * @param {String} event
   * @return {Boolean}
   */
  static hasPixelFire(campaignId, event) {
    const pixelLog = PixelService.getPixelLog();
    let hasPixelFire = false;
    const expiration = Date.now() - 3600*24*30;
    if (pixelLog[campaignId]
      && pixelLog[campaignId][event]
      && pixelLog[campaignId][event] > expiration
    ) {
      hasPixelFire = true;
    }
    return hasPixelFire;
  }

  /**
   *
   * @return {Object}
   */
  static getPixelLog() {
    const campaignPixels = cacheManager.getItem('pixelLog');
    let pixelLog = {};
    if (campaignPixels) {
      try {
        pixelLog = JSON.parse(campaignPixels);
      } catch (e) {
      }
    }
    return pixelLog;
  }

  /**
   *
   * @param {String} campaignId
   * @param {String} event
   */
  static logPixel(campaignId, event) {
    const pixelLog = PixelService.getPixelLog();
    pixelLog[campaignId] = pixelLog[campaignId] || {};
    pixelLog[campaignId][event] = Date.now();
    cacheManager.setItem('pixelLog', JSON.stringify(pixelLog));
  }

  /**
   * Creates and iframe and resolves the promise
   * after the iframe has done loading
   * @param {String} pixel
   * @return {Promise}
   */
  static attachPixel(pixel) {
    return new Promise((resolve, reject) => {
      const iFrame = document.createElement('iframe');
      iFrame.width = 1;
      iFrame.height = 1;
      iFrame.loading = 'lazy';
      iFrame.fetchpriority = 'low';
      iFrame.frameborder = 0;
      iFrame.style.display = 'none';

      document.body.appendChild(iFrame);

      iFrame
        .addEventListener('load', function() {
          resolve();
        });

      iFrame.contentWindow.document.open();
      iFrame.contentWindow.document.write(pixel);
      iFrame.contentWindow.document.close();
    });
  }

  /**
   *
   * @param {String} page
   */
  static trackPageLoad(page) {
    try {
      // Google Analytics
      window.ga('set', 'page', page);
      window.ga('send', 'pageview');

      // Bing UET
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'page_view', {'page_path': page});
    } catch (e) {}
  }

  /**
   *
   * @return {String}
   */
  static getSourceId() {
    const sourceInfoCookie = Cookies.get(cookies.CAMPAIGN_INFO);
    let sourceId = '';

    if (sourceInfoCookie) {
      const sourceInfo = JSON.parse(decodeURIComponent(sourceInfoCookie));
      sourceId = sourceInfo?.id;
    }

    return sourceId;
  }

  /**
   * @param {String} event
   */
  static async hashValue(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  /**
   *
   * @param {String} page
   */
  static async setGtmEventMetadata(event) {
    const userEmail = localStorage.getItem(localStorageKeys.EMAIL);
    const gtmEventMetadata = {
      countryCode: Cookies.get(cookies.COUNTRY_CODE),
      userAgent: navigator?.userAgent ?? '',
      url: window?.location?.href ?? '',
      productHash: localStorage.getItem(localStorageKeys.CURRENT_PRODUCT_HASH),
      sourceTag: PixelService.getSourceId(),
      timestamp: Date.now(),
      conversionEventID: uuid(),
      externalID: Cookies.get(cookies.CJ_E_COOKIE) || uuid(),
      email: await this.hashValue(userEmail)
    };

    const cjData = Cookies.get(cookies.CJ_D_COOKIE);
    if (cjData) gtmEventMetadata.cjData = cjData;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(
      {
        'id': gtmEventMetadata.conversionEventID,
        event,
        'eventData': {
          'product': gtmEventMetadata.productHash,
          'timestamp': gtmEventMetadata.timestamp,
          'sourceTag': gtmEventMetadata.sourceTag,
          'userAgent': gtmEventMetadata.userAgent,
          'geo': gtmEventMetadata.countryCode,
          'externalID': gtmEventMetadata.externalID,
          ...(gtmEventMetadata.cjData && {'cjData': gtmEventMetadata.cjData}),
          'hashedEMail': gtmEventMetadata.email,
          'pageUrl': gtmEventMetadata.url,
        }
      }
    );
  }
}

export {
  PixelService
};
