import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {ModalService} from '../modal-service';

/**
 * @extends {AoflElement}
 */
@customElement('modal-close-button')
class ModalCloseButton extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * Creates an instance of ModalCloseButton.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'modal-close-button';

  @property({type: String, attribute: 'tabOutlineColor'})
  tabOutlineColor

  @property({type: Boolean, attribute: 'black-button'})
  'blackButton' = false;

  /**
   *
   */
  closeModal(e) {
    ModalService.hide();
  }

  /**
   *
   */
  firstUpdated() {
    super.firstUpdated();

    if (this.blackButton) {
      this.tabOutlineColor = 'tab-outline-blue';
    } else {
      this.tabOutlineColor = 'tab-outline-white';
    }
  }

  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default ModalCloseButton;
