/** eslint-disable */
import {Rotations} from '@aofl/rotations';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {cacheNamespaces, cookies, eventNames, queryParamKeys, rotations} from './constants-enumerate';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import rotationConditions from './__config/rotation-conditions';
import routesConfig from './__config/routes.js';
import Cookies from 'js-cookie';

/**
 * Rotation config object retrieved from S3
 */
const ROTATION_CONFIG = window.aofljsConfig?.rotationConfig;

const THIRTY_DAYS = 2592000;

const FALLBACK_CONFIG = {
  'baseline_version': '310',
  'conditions': {},
  'qualification_order': {},
  'versions': {'310': 'routes'},
  'weights': {}
};

/**
 *
 */
class RotationsService {
  /**
   * Determines whether or not to qualify a user for a given rotation based on
   * the last time they visited or global exclusion conditions
   * @param {Function} doRequalify
   * @param {Boolean} forceRequalify
   * @param {Object} exclusionOverrideOpts {wdio: false, intl: false}
   */
  static rotationRequalifyCheck(doRequalify, forceRequalify = false, exclusionOverrideOpts = {}) {
    if (Cookies.get(cookies.WDIO) && Cookies.get(cookies.WDIO) === 'baseline') return false;
    // Returns false if global exclusions conditions are met
    const allowRequest = this.checkGlobalExclusionConditions(exclusionOverrideOpts);
    if (!allowRequest) return false;

    const currentTime = Math.round((new Date()).getTime() / 1000);
    const timeLastVisitedData = new CacheManager(cacheNamespaces.TIME_LAST_VISITED, cacheTypeEnumerate.LOCAL);
    const timeLastVisited = timeLastVisitedData.getItem(cacheNamespaces.TIME_LAST_VISITED);
    if (forceRequalify || currentTime > timeLastVisited + THIRTY_DAYS) {
      return doRequalify();
    }

    return false;
  }
  /**
   * Checks to see if any global exclusion conditions are met, returning false if the request should be excluded
   * @param {Object} exclusionOverrideOpts
   */
  static checkGlobalExclusionConditions(exclusionOverrideOpts) {
    try {
      const globalExclusionConditions = this.getGlobalExclusionConditions();
      const finalExclusionConditions = Object.assign({}, globalExclusionConditions, exclusionOverrideOpts);
      for (const key in finalExclusionConditions) {
        if (finalExclusionConditions[key] === false) continue; // Overrides will set key to false if the condition is meant to be ignored
        if (typeof finalExclusionConditions[key]?.condition === 'function'
        && finalExclusionConditions[key]?.condition() // eslint-disable-line
        ) return false;
      }
      return true;
    } catch (err) {
      return true;
    }
  }
  /**
   * Returns an object of global exclusion conditions
   * @return {Object}
   */
  static getGlobalExclusionConditions() {
    return {
      wdio: {
        condition: () => {
          return Cookies.get(cookies.WDIO) && Cookies.get(cookies.WDIO) === 'baseline';
        }
      },
      intl: {
        condition: () => {
          return Cookies.get(cookies.COUNTRY_CODE) && Cookies.get(cookies.COUNTRY_CODE) !== 'US';
        }
      }
    };
  }
  /**
   *
   * Persisting the last time the user visited the site. For use in rotation conditions
   */
  static setTimeLastVisited() {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    const timeLastVisitedData = new CacheManager(cacheNamespaces.TIME_LAST_VISITED, cacheTypeEnumerate.LOCAL);
    timeLastVisitedData.setItem(cacheNamespaces.TIME_LAST_VISITED, currentTime);
  }
  /**
   *
   * Fires a rotation assignment event, if needed.
   * @param {String} currentRotationVersion
   */
  static fireRotationAssignment(currentRotationVersion, currentPage) {
    const rotationData = new CacheManager(cacheNamespaces.ROTATION_VERSION, cacheTypeEnumerate.LOCAL, 7776000000);
    const storeRotationVersion = rotationData.getItem(cacheNamespaces.ROTATION_VERSION);

    if (storeRotationVersion === null || storeRotationVersion !== currentRotationVersion) {
      try {
        rotationData.setItem(cacheNamespaces.ROTATION_VERSION, currentRotationVersion);

        const rotationAssignmentEvent = {
          event_type: eventNames.ROTATION_ASSIGN,
          event_payload: {
            aofl_product: 'home_school',
            rotation_info: {
              site_section: 'regpath',
              page_name: currentPage,
              rotation_id: currentRotationVersion || null
            }
          }
        };
        marketingTrackerQueue.enqueueEvent(rotationAssignmentEvent);
      } catch (err) {}
    }
  }
  /**
   *
   * @param {Object} config
   * @param {String} version
   * @return {Object}
   */
  static setVersion(config, version) {
    const currentWeights = config.weights;
    let validVersion = false;

    for (const key in currentWeights) {
      if (!Object.hasOwnProperty.call(currentWeights, key)) continue;
      for (const id in currentWeights[key]) {
        if (!Object.hasOwnProperty.call(currentWeights[key], id)) continue;
        currentWeights[key][id] = 0;

        if (id === version) {
          validVersion = true;
          currentWeights[key][id] = 1;
        }
      }
    }

    if (!validVersion) {
      throw new Error(`The requested rotation version "${version}" does not exist`);
    }

    config.weights = currentWeights;
    return config;
  }
  /**
   * Returns a routes object based on either the retrieved rotation config
   * AWS, or the fallback config if an exception is thrown.
   *
   */
  static async getRoutes() {
    let routes = {};
    let rotationConfig = ROTATION_CONFIG;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const rotationVersion = urlSearchParams.get(queryParamKeys.ROTATION_VERSION);

    try {
      if (Cookies.get(cookies.WDIO) && Cookies.get(cookies.WDIO) !== 'baseline') {
        rotationConfig = this.setVersion(ROTATION_CONFIG, Cookies.get(cookies.WDIO));
      }

      if (rotationVersion) {
        rotationConfig = this.setVersion(ROTATION_CONFIG, rotationVersion);
      }

      const rotationInstance = new Rotations(rotations.MARKETING, routesConfig, rotationConfig, rotationConditions);
      routes = await rotationInstance.getRoutes();
    } catch (err) {
      const rotationInstance = new Rotations(rotations.MARKETING, routesConfig, FALLBACK_CONFIG, rotationConditions);
      routes = await rotationInstance.getRoutes();
    }

    return routes;
  }
}

export {
  RotationsService
};
