/**
 * @summary sdo-user-info
 * @version 1.0.0
 * @since 1.0.0
 * @author Alex Dinari <alex.dinari@aofl.com>
 */
import {Sdo, storeInstance, state} from '@aofl/store';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {deepAssign} from '@aofl/object-utils';
import {cacheNamespaces, localStorageKeys} from './constants-enumerate';

const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
const userData = userSessionData.getItem(cacheNamespaces.USER_SESSION);

/**
 * @type {String}
 * @private
 */
const USER_INFO_SDO = 'user-info';

/**
 * @extends {Sdo}
 */
class UserInfoSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = USER_INFO_SDO;

  /**
   * @static
   * @type {String}
   */
  @state()
  token = '';

  /**
   * @static
   * @type {String}
   */
  @state()
  email = localStorage.getItem(localStorageKeys.EMAIL) || '';

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  isLoggedIn = false;

  /**
   * @static
   * @type {Object}
   */
  @state()
  userInfo = {
    userToProductUuid: userData?.userToProductUuid || '',
    accountStatus: userData?.accountStatus || '',
    maskedEmail: userData?.maskedEmail || '',
    userPaymentProvider: userData?.userPaymentProvider || '',
    userMetadata: userData?.userMetadata || {},
    regPathKey: userData?.userMetadata?.subscription_path || '',
    completedStep: userData?.userMetadata?.completedStep || ''
  };

  /**
   *
   *
   * @param {String} userToProductUuid
   */
  setUserToProductUuid(userToProductUuid) {
    this.userInfo = deepAssign(this.userInfo, '', {
      userToProductUuid
    });
  }

  /**
   *
   *
   * @param {String} nextStep
   */
  setUserNextStep(nextStep) {
    this.userInfo = deepAssign(this.userInfo, '', {
      nextStep
    });
  }

  /**
   *
   *
   * @param {Boolean} isActive
   */
  setUserAccountStatus(accountStatus) {
    this.userInfo = deepAssign(this.userInfo, '', {
      accountStatus
    });
  }

  /**
   *
   *
   * @param {Boolean} isReactivated
   */
  setUserPaymentProvider(paymentProvider) {
    this.userInfo = deepAssign(this.userInfo, '', {
      paymentProvider
    });
  }

  /**
   *
   *
   * @param {Boolean} isReactivated
   */
  setUserMetadata(userMetadata) {
    this.userInfo = deepAssign(this.userInfo, '', {
      userMetadata
    });
  }

  /**
   *
   *
   * @param {Boolean} isReactivated
   */
  setUserCompletedStep(completedStep) {
    this.userInfo = deepAssign(this.userInfo, '', {
      completedStep
    });
  }
}

/**
 * @type {UserInfoSdo}
 */
const userInfoSdo = new UserInfoSdo();
storeInstance.addState(userInfoSdo);

export {
  userInfoSdo
};
